// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    users: [],
    currentUser: {},
    totalUsers: 0
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.users = action.payload.data;
            state.totalUsers = action.payload.total;
        },

        // GET CURRENT USER
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addUsers(users) {
    users.latitude = 23.004645;
    users.longitude = 65.056623;
    return async () => {
        try {
            await axios.post('/api/add_user', users);
            const response = await axios.get('/api/users');
            dispatch(slice.actions.getUsersSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'User save successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function getUsers(page, search = null, roleId = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    let filter = '';
    if (search) searchQuery = `&search=${search.replace(/&/g, '%26')}`;
    if (roleId) filter = `&role_id=${roleId}`;
    return async () => {
        try {
            const response = await axios.get(`/api/users?page=${page + searchQuery + filter}`);
            dispatch(slice.actions.getUsersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateUser(users) {
    return async () => {
        try {
            await axios.post('/api/update_user', users);
            const response = await axios.get('/api/users');
            dispatch(slice.actions.getUsersSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'User update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function changePassword(users) {
    return async () => {
        try {
            await axios.post('/api/changePassword', users);
            // const response = await axios.get('/api/me');
            // dispatch(slice.actions.getUsersSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Password Changed successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function resetUserPassword(users) {
    return async () => {
        try {
            const response = await axios.post('/api/user_password', users);
            // const response = await axios.get('/api/users');
            dispatch(slice.actions.getUsersSuccess(response.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'User update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function profileChange(values) {
    const formData = new FormData();
    formData.append('userId', values.userId);
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('mobile', values.mobile);
    formData.append('image', values.image);

    return async () => {
        try {
            await axios.post('/api/profileChange', formData);
            const response = await axios.get('/api/users');
            dispatch(slice.actions.getUsersSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Profile Changed successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: error?.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function getUserById(userID) {
    return async () => {
        try {
            const response = await axios.get(`/api/show_user?id=${userID}`);
            dispatch(slice.actions.setCurrentUser(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
