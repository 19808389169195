// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconBox,
    IconTrademark,
    IconWifi,
    IconReportAnalytics,
    IconTicket,
    IconSettings,
    IconReport
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconBox,
    IconTrademark,
    IconWifi,
    IconReportAnalytics,
    IconTicket,
    IconSettings,
    IconReport
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard'
        },
        {
            id: 'customers',
            title: <FormattedMessage id="customers" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/customers'
        },
        {
            id: 'chillers',
            title: <FormattedMessage id="chillers" />,
            type: 'item',
            icon: icons.IconBox,
            url: '/chillers'
        },
        {
            id: 'trademark',
            title: <FormattedMessage id="trademark" />,
            type: 'item',
            icon: icons.IconTrademark,
            url: '/trademark'
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/users'
        },
        {
            id: 'tickets',
            title: <FormattedMessage id="tickets" />,
            type: 'item',
            icon: icons.IconTicket,
            url: '/tickets'
        },
        {
            id: 'statistics',
            title: <FormattedMessage id="statistics" />,
            type: 'item',
            icon: icons.IconReportAnalytics,
            url: '/statistics'
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            icon: icons.IconReport,
            url: '/reports'
        },
        {
            id: 'area-covered',
            title: <FormattedMessage id="area-covered" />,
            type: 'item',
            icon: icons.IconWifi,
            url: '/area-covered'
        },
        {
            id: 'safety-area',
            title: <FormattedMessage id="safety-area" />,
            type: 'item',
            icon: icons.IconWifi,
            url: '/safety-area'
        },
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'profile',
                    title: <FormattedMessage id="profile" />,
                    type: 'item',
                    url: '/profile'
                },
                {
                    id: 'general-setting',
                    title: <FormattedMessage id="general-setting" />,
                    type: 'collapse',
                    children: [
                        {
                            id: 'zones-areas',
                            title: <FormattedMessage id="zones-areas" />,
                            type: 'collapse',
                            children: [
                                {
                                    id: 'zone',
                                    title: <FormattedMessage id="zone" />,
                                    type: 'item',
                                    url: '/setting/zone'
                                },
                                {
                                    id: 'area',
                                    title: <FormattedMessage id="area" />,
                                    type: 'item',
                                    url: '/setting/area'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'customer',
                    title: <FormattedMessage id="customer" />,
                    type: 'collapse',
                    children: [
                        {
                            id: 'safety-level',
                            title: <FormattedMessage id="safety-level" />,
                            type: 'item',
                            url: '/setting/safetylevel'
                        }
                    ]
                },
                {
                    id: 'chillers',
                    title: <FormattedMessage id="chillers" />,
                    type: 'collapse',
                    children: [
                        {
                            id: 'make',
                            title: <FormattedMessage id="make" />,
                            type: 'item',
                            url: '/setting/types'
                        },
                        {
                            id: 'model',
                            title: <FormattedMessage id="model" />,
                            type: 'item',
                            url: '/setting/modals'
                        },
                        {
                            id: 'brands',
                            title: <FormattedMessage id="brands" />,
                            type: 'item',
                            url: '/setting/brands'
                        },
                        {
                            id: 'status',
                            title: <FormattedMessage id="status" />,
                            type: 'item',
                            url: '/setting/status'
                        },
                        {
                            id: 'spare-parts',
                            title: <FormattedMessage id="spare-parts" />,
                            type: 'item',
                            url: '/setting/spare-parts'
                        }
                    ]
                }
                // {
                //     id: 'tickets',
                //     title: <FormattedMessage id="tickets" />,
                //     type: 'collapse',
                //     children: [
                //         {
                //             id: 'tickets-type',
                //             title: <FormattedMessage id="tickets-type" />,
                //             type: 'item',
                //             url: '/setting/ticketsType'
                //         },
                //         {
                //             id: 'tickets-status',
                //             title: <FormattedMessage id="tickets-status" />,
                //             type: 'item',
                //             url: '/setting/ticketsStatus'
                //         }
                //     ]
                // }
                // {
                //     id: 'ticket-questions',
                //     title: <FormattedMessage id="ticket-questions" />,
                //     type: 'item',
                //     url: '/setting/questions'
                // }
            ]
        }
    ]
};

export default pages;
