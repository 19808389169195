/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { API_URL } from '../config';

const axiosServices = axios.create({ baseURL: API_URL, timeout: 20000 });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error);
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

export default axiosServices;
