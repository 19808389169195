// third-party
import { createSlice } from '@reduxjs/toolkit';
import { openSnackbar } from 'store/slices/snackbar';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    trademarks: [],
    chillersByTrademark: [],
    customerByTrademark: [],
    clientByTrademark: [],
    agreementByTrademark: [],
    agreementAddByTrademark: [],
    productsByTrademark: [],
    currentTrademark: {},
    currentProduct: {},
    totalTrademarks: 0,
    totalClientByTrademark: 0,
    totalTrademarkChillers: 0,
    totalTrademarkProducts: 0,
    totalTrademarkCustomers: 0
};

const slice = createSlice({
    name: 'trademark',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET TRADEMARK
        getTrademarksSuccess(state, action) {
            state.trademarks = action.payload.data;
            state.totalTrademarks = action.payload.total;
        },

        // CURRENT TRADEMARK
        currentTrademarkSuccess(state, action) {
            state.currentTrademark = action.payload;
        },
        // CURRENT PRODUCT
        currentProductSuccess(state, action) {
            state.currentProduct = action.payload;
        },

        // ADD TRADEMARK
        addTrademarksSuccess(state, action) {
            state.trademarks = action.payload;
        },

        // GET ASSIGN CHILLER
        getAssignChillersSuccess(state, action) {
            state.assignChillers = action.payload;
        },

        // GET CHILLER
        getTrademarkChillersSuccess(state, action) {
            state.chillersByTrademark = action.payload.data;
            state.totalTrademarkChillers = action.payload.total;
        },

        // GET CUSTOMER
        getTrademarkCustomerSuccess(state, action) {
            state.customerByTrademark = action.payload.data;
            state.totalTrademarkCustomers = action.payload.total;
        },
        // GET PRODUCT
        getTrademarkProductSuccess(state, action) {
            state.productsByTrademark = action.payload.data;
            state.totalTrademarkProducts = action.payload.total;
        },

        // GET CLIENTS
        getTrademarkClientSuccess(state, action) {
            state.clientByTrademark = action.payload.data;
            state.totalClientByTrademark = action.payload.total;
        },

        // GET AGREEMNET
        getTrademarkAgreementSuccess(state, action) {
            state.agreementByTrademark = action.payload;
        },

        // ADD AGREEMNET
        addAgreementSuccess(state, action) {
            state.agreementAddByTrademark = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTrademarks(page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(`/api/projects?page=${page + searchQuery}`);
            dispatch(slice.actions.getTrademarksSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addTrademarks(trademarks) {
    const formData = new FormData();
    formData.append('name', trademarks.trademarkName);
    formData.append('photo', trademarks.trademarkLogo);
    formData.append('on_the_behalf_of', trademarks.on_the_behalf_of);
    formData.append('logo', trademarks.on_the_behalf_of_logo);
    return async () => {
        try {
            await axios.post('/api/add_project', formData);
            const response = await axios.get('/api/projects');
            dispatch(slice.actions.getTrademarksSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Projects save successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: error?.errors?.name[0],
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}
export function addProduct(trademarkId, values) {
    return async () => {
        try {
            await axios.post('/api/add_trade_product', values);
            const response = await axios.get(`/api/trademarkProduct?id=${trademarkId}`);
            dispatch(slice.actions.getTrademarkProductSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Product added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function updateTrademark(value) {
    const formData = new FormData();
    formData.append('id', value.id);
    formData.append('projectId', value.projectId);
    formData.append('name', value.name);
    formData.append('on_the_behalf_of', value.on_the_behalf_of);
    if (value?.photo) {
        formData.append('photo', value.photo);
    }
    if (value?.on_the_behalf_of_logo) {
        formData.append('logo', value.on_the_behalf_of_logo);
    }
    return async () => {
        try {
            await axios.post('/api/update_project', formData);
            const response = await axios.get('/api/projects');
            dispatch(slice.actions.getTrademarksSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Trademark update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function updateProduct(trademarkId, values) {
    return async () => {
        try {
            await axios.post('/api/update_trade_product', values);
            const response = await axios.get(`/api/trademarkProduct?id=${trademarkId}`);
            dispatch(slice.actions.getTrademarkProductSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Product updated successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function getTrademarkById(trademarkId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/show_project?id=${trademarkId}`)
            .then((response) => {
                dispatch(slice.actions.currentTrademarkSuccess(response.data));
                resolve(response.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getProductById(productId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/show_trade_product?id=${productId}`)
            .then((response) => {
                dispatch(slice.actions.currentProductSuccess(response.data.data));
                resolve(response.data.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getChillerByTrademarkId(trademarkId, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(`/api/get_chillers_by_projectid?id=${trademarkId}&page=${page + searchQuery}`);
            dispatch(slice.actions.getTrademarkChillersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCustomerByTrademarkId(trademarkId, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(`/api/getCustomersByTradeMarkID?id=${trademarkId}&page=${page + searchQuery}`);
            dispatch(slice.actions.getTrademarkCustomerSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductsByTrademarkId(trademarkId, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(`/api/trademarkProduct?id=${trademarkId}&page=${page + searchQuery}`);
            dispatch(slice.actions.getTrademarkProductSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getClientByTrademarkId(trademarkId, page) {
    page = !page ? 1 : page;
    return async () => {
        try {
            const response = await axios.get(`/api/getClientsByTradeMarkID/${trademarkId}?page=${page}`);
            dispatch(slice.actions.getTrademarkClientSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAgreementByTrademarkId(trademarkId, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) searchQuery = `&search=${search.replace(/&/g, '%26')}`;
    return async () => {
        try {
            const response = await axios.get(`/api/templates/${trademarkId}`);
            dispatch(slice.actions.getTrademarkAgreementSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addAgreement(agreement) {
    const formData = new FormData();
    formData.append('header', agreement.header);
    formData.append('rules', agreement.rules);
    formData.append('footer_content', agreement.footer_content);
    formData.append('footer_copyright_image', agreement.footer_copyright_image);
    formData.append('logo_image', agreement.logo_image);
    formData.append('trademark_id', agreement.trademark_id);
    formData.append('address', agreement.address);

    return async () => {
        try {
            await axios.post('/api/templates', formData);
            // const response = await axios.get('/api/templates');
            // dispatch(slice.actions.addAgreementSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Agreement save successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Server error',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}
export function updateAgreement(agreement) {
    const formData = new FormData();
    formData.append('id', agreement.id);
    formData.append('header', agreement.header);
    formData.append('rules', agreement.rules);
    formData.append('footer_content', agreement.footer_content);
    if (agreement?.footer_copyright_image) {
        formData.append('footer_copyright_image', agreement.footer_copyright_image);
    }
    if (agreement?.logo_image) {
        formData.append('logo_image', agreement.logo_image);
    }
    formData.append('trademark_id', agreement.trademark_id);
    formData.append('address', agreement.address);

    return async () => {
        try {
            await axios.post('/api/update_template', formData);
            // const response = await axios.get('/api/templates');
            // dispatch(slice.actions.addAgreementSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Agreement update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Server error',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}
