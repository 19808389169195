import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Avatar, Button, Grid, Stack, TextField, Typography } from '@mui/material';

// import assets
import MainLogo from '../../assets/images/Logo_AiLogistic.jpg';

const AccessDenied = () => (
    <Grid container direction="column" alignItems="center" sx={{ justifyContent: 'center' }} style={{ minHeight: '100vh' }}>
        <Grid item sm={12}>
            <Typography variant="h1" align="center" style={{ fontSize: '60px', fontWeight: 'normal' }}>
                403 Forbidden
            </Typography>
        </Grid>
        {/* <Grid item sx={{ my: 2 }}>
            <Link to="/">
                <img src={MainLogo} alt="AI LOGISTIC" />
            </Link>
        </Grid> */}
        <Grid item sm={12}>
            <Typography
                variant="h1"
                align="center"
                sx={{
                    py: 2
                }}
                style={{ fontWeight: 'normal' }}
            >
                Look like you are lost
            </Typography>
            <Typography align="center">You have not permission to open this page!</Typography>
        </Grid>
        <Grid
            item
            xs={12}
            sx={{
                py: 3
            }}
        >
            <Stack direction="row" sx={{ justifyContent: 'center' }}>
                <AnimateButton alignSelf="right">
                    <Button variant="contained" type="submit" alignSelf="center" component={Link} to="/">
                        Back to Home
                    </Button>
                </AnimateButton>
            </Stack>
        </Grid>
    </Grid>
);

export default AccessDenied;
