// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    chillers: [],
    chillerHistory: [],
    assignChillers: [],
    currentStoreChiller: [],
    currentChiller: {},
    totalChillers: 0,
    totalAssignChillers: 0,
    totalChillerHistory: 0,
    perPage: 0,
    pageFrom: 0,
    pageTo: 0,
    lastPage: 0,
    currentPage: 0
};

const slice = createSlice({
    name: 'chiller',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CHILLER
        getChillersSuccess(state, action) {
            state.chillers = action.payload.data;
            state.totalChillers = action.payload.total;
            state.perPage = action.payload.per_page;
            state.pageFrom = action.payload.from;
            state.pageTo = action.payload.to;
            state.lastPage = action.payload.last_page;
            state.currentPage = action.payload.current_page;
        },

        // GET CHILLER HISTORY
        getChillerHistorySuccess(state, action) {
            state.chillerHistory = action.payload;
        },

        // GET ASSIGN CHILLER
        getAssignChillersSuccess(state, action) {
            state.assignChillers = action.payload.data;
            state.totalAssignChillers = action.payload.total;
        },

        // GET ASSIGN CHILLER
        setCurrentChiller(state, action) {
            state.currentChiller = action.payload;
        },

        // GET STORE CHILLER
        setCurrentStoreChiller(state, action) {
            state.currentStoreChiller = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getChillers(page, search = null, status = null, trademark, modelId, brandId, typeId) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(
                `/api/chillers?page=${page + searchQuery}&status=${status || ''}&project_id=${trademark || ''}&model_id=${
                    modelId || ''
                }&brand_id=${brandId || ''}&type_id=${typeId || ''}`
            );
            dispatch(slice.actions.getChillersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getChillerHistory(chillerID, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(`/api/chiller_history?id=${chillerID}&page=${page + searchQuery}`);
            dispatch(slice.actions.getChillerHistorySuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getChillersByCustomer(customerID, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            // dispatch(slice.actions.getAssignChillersSuccess([]));
            const response = await axios.get(`/api/getChillersByCustomerID?customerID=${customerID}&page=${page + searchQuery}`);
            dispatch(slice.actions.getAssignChillersSuccess(response.data.data));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export async function getChillersByStoreID(storeID) {
    try {
        const response = await axios.get(`/api/getChillersByStoreID/${storeID}`);
        dispatch(slice.actions.setCurrentStoreChiller(response.data.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export async function getChillerById(chillerID) {
    try {
        const response = await axios.get(`/api/show_chiller?id=${chillerID}`);
        dispatch(slice.actions.setCurrentChiller(response.data.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export function getAvailChiller(trademarkId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/chillers/listing?id=${trademarkId}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function addChillers(chillers) {
    chillers.serial_number = chillers.serial;
    chillers.type_id = chillers.type;
    chillers.brand_id = chillers.brand;
    chillers.modal_id = chillers.model;
    chillers.project_id = chillers.trademark;
    chillers.service_by = chillers.serviceby;
    chillers.purchase_date = chillers.purchaseDate;
    chillers.modem_id = chillers.model;
    return async () => {
        try {
            await axios.post('/api/add_chiller', chillers);
            const response = await axios.get('/api/chillers?page=1');
            dispatch(slice.actions.getChillersSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Chiller save successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateChiller(chillerID, currentPage) {
    return async () => {
        try {
            await axios.post('/api/update_chiller', chillerID);
            const response = await axios.get(`/api/chillers?page=${currentPage}`);
            // const response = await axios.get(`/api/chillers`);
            dispatch(slice.actions.getChillersSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Chiller update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}
