import pages from './pages';
import adminPages from './adminPages';
import trademarkPages from './trademarkPages';
import user from './user';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    superAdmin: [pages],
    user: [user],
    adminItems: [adminPages],
    trademakItems: [trademarkPages]
};

export default menuItems;
