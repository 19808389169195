// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconBox,
    IconTrademark,
    IconWifi,
    IconReportAnalytics,
    IconTicket,
    IconSettings
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconBox,
    IconTrademark,
    IconWifi,
    IconReportAnalytics,
    IconTicket,
    IconSettings
};

// ==============================|| TRADEMARK LOGGED IN PAGES MENU ITEMS ||============================== //

const trademarkPages = {
    id: 'trademarkPages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard'
        },
        {
            id: 'customers',
            title: <FormattedMessage id="customers" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/customers'
        },
        {
            id: 'chillers',
            title: <FormattedMessage id="chillers" />,
            type: 'item',
            icon: icons.IconBox,
            url: '/chillers'
        },
        {
            id: 'tickets',
            title: <FormattedMessage id="tickets" />,
            type: 'item',
            icon: icons.IconTicket,
            url: '/tickets'
        },
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'profile',
                    title: <FormattedMessage id="profile" />,
                    type: 'item',
                    url: '/profile'
                }
            ]
        }
    ]
};

export default trademarkPages;
