// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    tickets: [],
    ticketHistory: [],
    currentCustomerTickets: [],
    currentTrademarkTickets: [],
    currentTechnicianTickets: [],
    currentTicket: {},
    totalTickets: 0,
    totalCurrentTechnicianTickets: 0,
    totalCustomerTickets: 0,
    totalTrademarkTickets: 0
};

const slice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET TICKETS
        getTicketsSuccess(state, action) {
            state.tickets = action.payload.data;
            state.totalTickets = action.payload.total;
        },

        // GET TICKETS HISTORY
        setTicketHistory(state, action) {
            state.ticketHistory = action.payload;
        },

        // ADD TICKETS
        addTicketSuccess(state, action) {
            state.tickets = action.payload;
        },

        // Set Current Customer
        setCurrentTicket(state, action) {
            state.currentTicket = action.payload;
        },

        // Set Customer Ticket
        setCurrentCustomerTickets(state, action) {
            state.currentCustomerTickets = action.payload.data;
            state.totalCustomerTickets = action.payload.total;
        },

        // Set Trademark Ticket
        setCurrentTrademarkTickets(state, action) {
            state.currentTrademarkTickets = action.payload.data;
            state.totalTrademarkTickets = action.payload.total;
        },

        // Set Technician Ticket
        setCurrentTechnicianTickets(state, action) {
            state.currentTechnicianTickets = action.payload.data;
            state.totalCurrentTechnicianTickets = action.payload.total;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTickets(
    page,
    search = null,
    type = null,
    status = null,
    trademark = null,
    customerId = null,
    areaId = null,
    technicianId = null,
    to = null,
    from = null
) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(
                `/api/ticket?page=${page + searchQuery}&type=${type || ''}&status=${status || ''}&trademark=${
                    trademark || ''
                }&customer_id=${customerId || ''}&area_id=${areaId || ''}&technician_id=${technicianId || ''}&to=${to || ''}&from=${
                    from || ''
                }`
            );
            dispatch(slice.actions.getTicketsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTicketById(ticketID) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/show_ticket?id=${ticketID}`)
            .then((response) => {
                dispatch(slice.actions.setCurrentTicket(response?.data?.data));
                resolve(response?.data?.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getTicketHistory(ticketID) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/ticketHistroy?id=${ticketID}`)
            .then((response) => {
                dispatch(slice.actions.setTicketHistory(response?.data?.data));
                resolve(response?.data?.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getTicketsByCustomerID(customerID, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/getTicketsByCustomerID/${customerID}?page=${page + searchQuery}`)
            .then((response) => {
                dispatch(slice.actions.setCurrentCustomerTickets(response.data.data));
                resolve(response.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getTicketsByTrademarkID(trademarkID, page, search = null, ticketType = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    let filter = '';
    if (ticketType) filter = `&type=${ticketType}`;
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/getTicketsByTrademarkID/${trademarkID}?page=${page + searchQuery + filter}`)
            .then((response) => {
                dispatch(slice.actions.setCurrentTrademarkTickets(response.data.data));
                resolve(response.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getTicketsByTechnicianID(technicianID, page) {
    page = !page ? 1 : page;
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/get_ticket_by_technician/${technicianID}?page=${page}`)
            .then((response) => {
                dispatch(slice.actions.setCurrentTechnicianTickets(response.data.data));
                resolve(response.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function addTicket(tickets) {
    return async () => {
        try {
            const addTicketResponse = await axios.post('/api/add_ticket', tickets);
            if (addTicketResponse.data.status === 'success') {
                const response = await axios.get('/api/ticket');
                dispatch(slice.actions.addTicketSuccess(response.data.data.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Ticket Generate successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Somethings went wrong! Reload and please try again',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function addBulkTicket(value) {
    return async () => {
        try {
            await axios.post('/api/bulkTicketCreation', value);
            const response = await axios.get('/api/ticket');
            dispatch(slice.actions.addTicketSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Bulk Ticket Generate successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateTicket(tickets, ticketID) {
    return async () => {
        try {
            await axios.post('/api/update_ticket', tickets);
            const response = await axios.get(`/api/show_ticket?id=${ticketID}`);
            dispatch(slice.actions.setCurrentTicket(response?.data?.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Ticket update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function getTicketTypes() {
    return async () => {
        try {
            const response = await axios.get('/api/type');
            dispatch(slice.actions.getTicketsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
