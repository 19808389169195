// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    areas: [],
    totalArea: ''
};

const slice = createSlice({
    name: 'areas',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // ADD Areas
        addAreaSuccess(state, action) {
            state.areas = action.payload;
        },

        // GET Areas
        getAreasSuccess(state, action) {
            state.areas = action.payload.data;
            state.totalArea = action.payload.total;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addAreas(areas) {
    return async () => {
        try {
            await axios.post('/api/add_area', areas);
            const response = await axios.get('/api/area');
            dispatch(slice.actions.addAreaSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Area saved successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateArea(values, typeId) {
    return async () => {
        try {
            await axios.post(`/api/update_area?id=${typeId}`, values);
            const response = await axios.get('/api/area');
            dispatch(slice.actions.addAreaSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Area updated successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getAreas(page, search) {
    return async () => {
        try {
            const response = await axios.get(`/api/area?page=${page}&search=${search}`);
            dispatch(slice.actions.getAreasSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
