import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/route-guard/RoleGuard';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// Profile routing
const Profile = Loadable(lazy(() => import('views/pages/profile/Profile')));
const ProfileDetails = Loadable(lazy(() => import('views/pages/profile/ProfileDetails')));
const ChangePassword = Loadable(lazy(() => import('views/pages/profile/ChangePassword')));

// application - customer routing
const AppCustomerList = Loadable(lazy(() => import('views/customer/CustomerList')));

// customer details routing
const AppCustomerListDetails = Loadable(lazy(() => import('views/customer/CustomerListDetails')));

// customer details tab routing
const CustomerTabGeneral = Loadable(lazy(() => import('views/customer/General')));
const CustomerTabChiller = Loadable(lazy(() => import('views/customer/ChillerList')));
const CustomerTabStore = Loadable(lazy(() => import('views/customer/Store')));
const CustomerTabTickets = Loadable(lazy(() => import('views/customer/TicketList')));
const CustomerTabChangeHistory = Loadable(lazy(() => import('views/customer/ChangeHistory')));
const CustomerTabAdditional = Loadable(lazy(() => import('views/customer/Additional')));
const CustomerStoreListing = Loadable(lazy(() => import('views/customer/StoreListing')));
const CustomerAddStore = Loadable(lazy(() => import('views/customer/StoreAdd')));

// trademark routing
const AppTrademarkList = Loadable(lazy(() => import('views/trademark/TrademarkList')));

// trademark details routing
const AppTrademarkListDetails = Loadable(lazy(() => import('views/trademark/TrademarkListDetails')));

// trademark details tab routing
const TrademarkTabChillerList = Loadable(lazy(() => import('views/trademark/ChillerList')));
const TrademarkTabCustomerList = Loadable(lazy(() => import('views/trademark/CustomerList')));
const TrademarkTabTicketList = Loadable(lazy(() => import('views/trademark/TicketList')));
const TrademarkTabProductList = Loadable(lazy(() => import('views/trademark/ProductList')));
const TrademarkTabClients = Loadable(lazy(() => import('views/trademark/ClientList')));
const TrademarkTabAgreement = Loadable(lazy(() => import('views/trademark/Agreement')));
const TrademarkTabAgreementAdd = Loadable(lazy(() => import('views/trademark/AgreementAdd')));
const TrademarkTabAgreementUpdate = Loadable(lazy(() => import('views/trademark/AgreementEdit')));
const TrademarkTabQuestion = Loadable(lazy(() => import('views/trademark/questions/Questions')));
const TrademarkTabQuestionAdd = Loadable(lazy(() => import('views/trademark/questions/QuestionAdd')));
const TrademarkTabQuestionUpdate = Loadable(lazy(() => import('views/trademark/questions/QuestionEdit')));

// Chillers routing
const AppChillerList = Loadable(lazy(() => import('views/chiller/ChillerList')));

// chiller details routing
const AppChillerListDetails = Loadable(lazy(() => import('views/chiller/ChillerListDetails')));

// chiller details tab routing
const ChillerTabGeneral = Loadable(lazy(() => import('views/chiller/General')));
const ChillerTabHistory = Loadable(lazy(() => import('views/chiller/History')));

// users routing
const AppUserList = Loadable(lazy(() => import('views/user/UserList')));
const AppUserDetails = Loadable(lazy(() => import('views/user/UserListDetail')));

// AreaCovered routing
const AppAreaCovered = Loadable(lazy(() => import('views/area-covered/AreaCovered')));

//
const AppSafetyArea = Loadable(lazy(() => import('views/safety-area/SafetyArea')));

// Reports
const AppReports = Loadable(lazy(() => import('views/Reports/Reports')));

// static routing
const AppStaticList = Loadable(lazy(() => import('views/static/StaticList')));

// tikets routing
const AppTicketList = Loadable(lazy(() => import('views/ticket/TicketList')));

// ticket details routing
const AppTicketListDetails = Loadable(lazy(() => import('views/ticket/TicketListDetails')));

// Setting routing
const Setting = Loadable(lazy(() => import('views/settings/Setting')));
const AppZoneList = Loadable(lazy(() => import('views/settings/general/Zone')));
const AppAreaList = Loadable(lazy(() => import('views/settings/general/Area')));
const AppBrandsList = Loadable(lazy(() => import('views/settings/chiller/Brand')));
const AppModalsList = Loadable(lazy(() => import('views/settings/chiller/Modal')));
const AppTypesList = Loadable(lazy(() => import('views/settings/chiller/Types')));
const AppStatusList = Loadable(lazy(() => import('views/settings/chiller/Status')));
const AppSafetyLevelList = Loadable(lazy(() => import('views/settings/customer/SafetyLevel')));
const AppTicketsTypeList = Loadable(lazy(() => import('views/settings/Tickets/TicketsType')));
const AppTicketsStatusList = Loadable(lazy(() => import('views/settings/Tickets/TicketsStatus')));
const AppTicketQuestions = Loadable(lazy(() => import('views/settings/questions/Questions')));

// Setting Spare Parts
const AppChillerSpareList = Loadable(lazy(() => import('views/settings/chiller/spare/SpareParts')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <Dashboard />
                </RoleGuard>
            )
        },
        {
            path: '/dashboard',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <Dashboard />
                </RoleGuard>
            )
        },
        {
            path: '/customers',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <AppCustomerList />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerID',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <AppCustomerListDetails />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/customers/:customerID',
                    element: <CustomerTabGeneral />
                },
                {
                    path: '/customers/:customerID/general',
                    element: <CustomerTabGeneral />
                },
                {
                    path: '/customers/:customerID/chiller-list',
                    element: <CustomerTabChiller />
                },
                {
                    path: '/customers/:customerID/store',
                    element: <CustomerTabStore />,
                    children: [
                        {
                            path: '/customers/:customerID/store',
                            element: <CustomerStoreListing />
                        },
                        {
                            path: '/customers/:customerID/store/add',
                            element: <CustomerAddStore />
                        },
                        {
                            path: '/customers/:customerID/store/:storeId',
                            element: <CustomerAddStore />
                        }
                    ]
                },

                {
                    path: '/customers/:customerID/ticket-list',
                    element: <CustomerTabTickets />
                },
                {
                    path: '/customers/:customerID/change-history',
                    element: <CustomerTabChangeHistory />
                }
                // {
                //     path: '/customers/:customerID/additional',
                //     element: <CustomerTabAdditional />
                // }
            ]
        },
        {
            path: '/chillers',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <AppChillerList />
                </RoleGuard>
            )
        },
        {
            path: '/chillers/:chillerID',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <AppChillerListDetails />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/chillers/:chillerID',
                    element: <ChillerTabGeneral />
                },
                {
                    path: '/chillers/:chillerID/general',
                    element: <ChillerTabGeneral />
                },
                {
                    path: '/chillers/:chillerID/history',
                    element: <ChillerTabHistory />
                }
            ]
        },
        {
            path: '/trademark',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppTrademarkList />
                </RoleGuard>
            )
        },
        {
            path: '/trademark/:trademarkId',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppTrademarkListDetails />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/trademark/:trademarkId',
                    element: <TrademarkTabChillerList />
                },
                {
                    path: '/trademark/:trademarkId/customer',
                    element: <TrademarkTabCustomerList />
                },
                {
                    path: '/trademark/:trademarkId/chiller',
                    element: <TrademarkTabChillerList />
                },
                {
                    path: '/trademark/:trademarkId/products',
                    element: <TrademarkTabProductList />
                },
                {
                    path: '/trademark/:trademarkId/ticket-list',
                    element: <TrademarkTabTicketList />
                },
                {
                    path: '/trademark/:trademarkId/clients',
                    element: <TrademarkTabClients />
                },
                {
                    path: '/trademark/:trademarkId/agreement',
                    element: <TrademarkTabAgreement />
                },
                {
                    path: '/trademark/:trademarkId/agreement/add',
                    element: <TrademarkTabAgreementAdd />
                },
                {
                    path: '/trademark/:trademarkId/agreement/:agreementId/edit',
                    element: <TrademarkTabAgreementUpdate />
                },
                {
                    path: '/trademark/:trademarkId/questions',
                    element: <TrademarkTabQuestion />
                },
                {
                    path: '/trademark/:trademarkId/question/add',
                    element: <TrademarkTabQuestionAdd />
                },
                {
                    path: '/trademark/:trademarkId/question/:questionId/edit',
                    element: <TrademarkTabQuestionUpdate />
                }
            ]
        },
        {
            path: '/users',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppUserList />
                </RoleGuard>
            )
        },
        {
            path: '/user/:userID',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppUserDetails />
                </RoleGuard>
            )
        },
        {
            path: '/statistics',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppStaticList />
                </RoleGuard>
            )
        },
        {
            path: '/tickets',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <AppTicketList />
                </RoleGuard>
            )
        },
        {
            path: '/ticket/:ticketID',
            element: (
                <RoleGuard rolePermission={[1, 2, 3]}>
                    <AppTicketListDetails />
                </RoleGuard>
            )
        },
        {
            path: '/area-covered',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppAreaCovered />
                </RoleGuard>
            )
        },
        {
            path: '/safety-area',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppSafetyArea />
                </RoleGuard>
            )
        },
        {
            path: '/reports',
            element: (
                <RoleGuard rolePermission={[1, 2]}>
                    <AppReports />
                </RoleGuard>
            )
        },
        {
            path: '/setting',
            element: (
                <RoleGuard rolePermission={[1]}>
                    <Setting />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/setting/zone',
                    element: <AppZoneList />
                },
                {
                    path: '/setting/area',
                    element: <AppAreaList />
                },
                {
                    path: 'setting/profile',
                    element: <ProfileDetails />
                },
                {
                    path: '/setting/brands',
                    element: <AppBrandsList />
                },
                {
                    path: '/setting/modals',
                    element: <AppModalsList />
                },
                {
                    path: '/setting/types',
                    element: <AppTypesList />
                },
                {
                    path: '/setting/status',
                    element: <AppStatusList />
                },
                {
                    path: '/setting/safetylevel',
                    element: <AppSafetyLevelList />
                },
                {
                    path: '/setting/ticketsType',
                    element: <AppTicketsTypeList />
                },
                {
                    path: '/setting/ticketsStatus',
                    element: <AppTicketsStatusList />
                },
                {
                    path: '/setting/questions',
                    element: <AppTicketQuestions />
                },
                {
                    path: '/setting/spare-parts',
                    element: <AppChillerSpareList />
                }
            ]
        },

        {
            path: '/user-setting',
            element: (
                <RoleGuard rolePermission={[2, 3, 4, 5]}>
                    <Profile />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/user-setting/profile',
                    element: <Profile />
                }
            ]
        },

        {
            path: '/profile',
            element: <Profile />,
            children: [
                {
                    path: '/profile',
                    element: <ProfileDetails />
                },
                {
                    path: '/profile/profile-details',
                    element: <ProfileDetails />
                },
                {
                    path: '/profile/change-password',
                    element: <ChangePassword />
                }
            ]
        }
    ]
};

export default MainRoutes;
