import React from 'react';
import { useDispatch, useSelector } from 'store';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Divider, Grid, List, Stack, Typography } from '@mui/material';

// assets
import { getNotification } from 'store/slices/masterOption';
import moment from 'moment';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 12,
    paddingBottom: 8,
    paddingTop: 8,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { notification } = useSelector((state) => state.masterOption);

    React.useEffect(() => {
        dispatch(getNotification());
    }, [dispatch]);

    const DateFormat = 'YYYY-MM-DD';
    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notification.map((data, index) => (
                <Stack
                    key={index}
                    style={{
                        cursor: 'pointer',
                        padding: 12,
                        paddingBottom: 8,
                        paddingTop: 8,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light
                        },
                        '& .MuiListItem-root': {
                            padding: 0
                        }
                    }}
                >
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">{capitalizeFirst(data?.title)}</Typography>
                        </Grid>
                        <Grid item alignSelf="right">
                            <Typography variant="caption">{moment(data?.created_at).format(DateFormat)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography variant="body" lineHeight="1.8">
                                {data?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
            ))}
        </List>
    );
};

export default NotificationList;
