// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import customerReducer from './slices/customer';
import chillerReducer from './slices/chiller';
import trademarkReducer from './slices/trademark';
import userReducer from './slices/user';
import ticketReducer from './slices/ticket';
import zoneReducer from './slices/zone';
import areaReducer from './slices/area';
import masterOptionReducer from './slices/masterOption';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    customer: customerReducer,
    chiller: chillerReducer,
    trademark: trademarkReducer,
    user: userReducer,
    ticket: ticketReducer,
    zone: zoneReducer,
    area: areaReducer,
    masterOption: masterOptionReducer
});

export default reducer;
