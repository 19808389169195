import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// project imports
import useAuth from 'hooks/useAuth';
import AccessDenied from 'views/pages/AccessDenied';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const RoleGuard = ({ children, rolePermission }) => {
    const { user } = useAuth();

    return rolePermission.includes(user?.role_id) ? children : <AccessDenied />;
};

RoleGuard.propTypes = {
    children: PropTypes.node
};

export default RoleGuard;
