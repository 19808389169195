// project imports

import PageNotFound from 'views/pages/PageNotFound';

// ==============================|| AUTH ROUTING ||============================== //

const NotFoundRoutes = {
    path: '*',
    element: <PageNotFound />
};

export default NotFoundRoutes;
