// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    notification: [],
    brands: [],
    totalBrand: '',
    modals: [],
    totalModals: '',
    trademarks: [],
    status: [],
    totalStatus: '',
    types: [],
    questions: [],
    totalQuestions: '',
    totalTypes: '',
    ticketTypes: [],
    ticketStatus: [],
    safetyLevel: [],
    totalSafetyLevel: '',
    area: [],
    chillerSpare: [],
    totalChillerSpare: '',
    dashboard: {}
};

const slice = createSlice({
    name: 'masterOption',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET NOTIFICATIONS
        getNotificationSuccess(state, action) {
            state.notification = action.payload;
        },

        // GET BRANDS
        getBrandsSuccess(state, action) {
            state.brands = action.payload.data;
            state.totalBrand = action.payload.total;
        },

        // ADD BRANDS
        addBrandSuccess(state, action) {
            state.brands = action.payload;
        },

        // GET MODAL
        getModalsSuccess(state, action) {
            state.modals = action.payload.data;
            state.totalModals = action.payload.total;
        },

        // ADD MODAL
        addModalSuccess(state, action) {
            state.modals = action.payload;
        },

        // GET TRADEMARKS
        getTrademarksSuccess(state, action) {
            state.trademarks = action.payload;
        },

        // GET STATUS
        getStatusSuccess(state, action) {
            state.status = action.payload.data;
            state.totalStatus = action.payload.total;
        },

        // ADD STATUS
        addStatusSuccess(state, action) {
            state.status = action.payload;
        },

        // GET TYPES
        getTypesSuccess(state, action) {
            state.types = action.payload.data;
            state.totalTypes = action.payload.total;
        },

        // ADD TYPES
        addTypesSuccess(state, action) {
            state.types = action.payload;
        },

        // GET SAFETY LEVEL
        getSafetylevelSuccess(state, action) {
            state.safetyLevel = action.payload.data;
            state.totalSafetyLevel = action.payload.total;
        },

        // ADD SAFETY LEVEL
        addSafetyLevelSuccess(state, action) {
            state.safetyLevel = action.payload;
        },

        // GET QUESTIONS
        getQuestionsSuccess(state, action) {
            state.questions = action.payload.data;
            state.totalQuestions = action.payload.total;
        },

        // ADD QUESTIONS
        addQuestionsSuccess(state, action) {
            state.questions = action.payload;
        },

        // GET AREA
        getAreaSuccess(state, action) {
            state.area = action.payload;
        },

        // ADD AREA
        addAreaSuccess(state, action) {
            state.area = action.payload;
        },

        // GET DASHBOARD
        getDashboardSuccess(state, action) {
            state.dashboard = action.payload;
        },

        // GET TICKET TYPE
        getTicketTypesSuccess(state, action) {
            state.ticketTypes = action.payload;
        },

        // ADD TICKET TYPE
        addTicketTypesSuccess(state, action) {
            state.ticketTypes = action.payload;
        },

        // GET TICKET STATUS
        getTicketStatusSuccess(state, action) {
            state.ticketStatus = action.payload;
        },

        // ADD TICKET STATUS
        addTicketStatusSuccess(state, action) {
            state.ticketStatus = action.payload;
        },

        // GET CHILLER SPARE
        getChillerSpareSuccess(state, action) {
            state.chillerSpare = action.payload.data;
            state.totalChillerSpare = action.payload.total;
        },

        // ADD CHILLER SPARE
        addChillerSpareSuccess(state, action) {
            state.chillerSpare = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotification() {
    return async () => {
        try {
            const response = await axios.get('/api/notificaiton');
            dispatch(slice.actions.getNotificationSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBrands(page, search) {
    return async () => {
        try {
            const response = await axios.get(`/api/brands?${page && `page=${page}`} &search=${search || ''}`);
            dispatch(slice.actions.getBrandsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addBrand(values) {
    return async () => {
        try {
            await axios.post('/api/add_brand', values);
            const response = await axios.get('/api/brands');
            dispatch(slice.actions.addBrandSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Branding added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateBrand(values, currentBrandId) {
    return async () => {
        try {
            await axios.post(`/api/update_brand?id=${currentBrandId}`, values);
            const response = await axios.get('/api/brands');
            dispatch(slice.actions.addBrandSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Branding updated successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getModals(page, search) {
    return async () => {
        try {
            const response = await axios.get(`/api/modal?${page && `page=${page}`} &search=${search || ''}`);
            dispatch(slice.actions.getModalsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addModal(values) {
    return async () => {
        try {
            await axios.post('/api/add_modal', values);
            const response = await axios.get('/api/modal');
            dispatch(slice.actions.addModalSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Modals added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateModal(values, modalId) {
    return async () => {
        try {
            await axios.post(`/api/update_modal?id=${modalId}`, values);
            const response = await axios.get('/api/modal');
            dispatch(slice.actions.addModalSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Modal added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getQuestions(trademarkId, page, newString) {
    return async () => {
        try {
            const response = await axios.get(`/api/question?id=${trademarkId}&${page && `page=${page}`}&search=${newString || ''}`);
            dispatch(slice.actions.getQuestionsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addQuestion(values) {
    return async () => {
        try {
            await axios.post('/api/add_question', values);
            const response = await axios.get(`/api/question?id=${values.trademark_id}`);
            dispatch(slice.actions.addQuestionsSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Question added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateQuestion(values) {
    return async () => {
        try {
            await axios.post('/api/update_question', values);
            const response = await axios.get(`/api/question?id=${values.trademark_id}`);
            dispatch(slice.actions.addQuestionsSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Question Updated successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getChillerSpare(page, newString) {
    return async () => {
        try {
            const response = await axios.get(`/api/sparepart?${page && `page=${page}`} &search=${newString || ''}`);
            dispatch(slice.actions.getChillerSpareSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addChillerSpare(values) {
    return async () => {
        try {
            await axios.post('/api/add_sparepart', values);
            const response = await axios.get('/api/sparepart');
            dispatch(slice.actions.addChillerSpareSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Spare added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateChillerSpare(values) {
    return async () => {
        try {
            await axios.post('/api/update_sparepart', values);
            const response = await axios.get('/api/sparepart');
            dispatch(slice.actions.addChillerSpareSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Spare Updated successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getTrademarks() {
    return async () => {
        try {
            const response = await axios.get('/api/projects');
            dispatch(slice.actions.getTrademarksSuccess(response.data.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getStatus(page, search) {
    return async () => {
        try {
            const response = await axios.get(`/api/status?${page && `page=${page}`} &search=${search || ''}`);
            dispatch(slice.actions.getStatusSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addStatus(values) {
    return async () => {
        try {
            await axios.post('/api/add_status', values);
            const response = await axios.get('/api/status');
            dispatch(slice.actions.addStatusSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Status added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateStatus(values, statusId) {
    return async () => {
        try {
            await axios.post(`/api/update_status?id=${statusId}`, values);
            const response = await axios.get('/api/status');
            dispatch(slice.actions.addStatusSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Status added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getTypes(page, search) {
    return async () => {
        try {
            const response = await axios.get(`/api/type?${page && `page=${page}`} &search=${search || ''}`);
            dispatch(slice.actions.getTypesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addType(values) {
    return async () => {
        try {
            await axios.post('/api/add_type', values);
            const response = await axios.get('/api/type');
            dispatch(slice.actions.addTypesSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Types added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}
export function updateType(values, typeId) {
    return async () => {
        try {
            await axios.post(`/api/update_type?id=${typeId}`, values);
            const response = await axios.get('/api/type');
            dispatch(slice.actions.addTypesSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Type added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getAreas(zoneID) {
    return async () => {
        try {
            const response = await axios.get(`/api/fetchAreasViaZone/${zoneID}`);

            dispatch(slice.actions.getAreaSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSafetylevel(page, search) {
    return async () => {
        try {
            const response = await axios.get(`/api/safetylevel?${page && `page=${page}&search=${search || ''}`}`);
            dispatch(slice.actions.getSafetylevelSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addSafetylevel(values) {
    return async () => {
        try {
            await axios.post('/api/add_safetylevel', values);
            const response = await axios.get('/api/safetylevel');
            dispatch(slice.actions.addSafetyLevelSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Safety Level added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateSafetyLevel(values, typeId) {
    return async () => {
        try {
            await axios.post(`/api/update_safetylevel?id=${typeId}`, values);
            const response = await axios.get('/api/safetylevel');
            dispatch(slice.actions.addSafetyLevelSuccess(response.data.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Safety Level update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getDashboard() {
    return async () => {
        try {
            const response = await axios.get('/api/web_dashboard');
            dispatch(slice?.actions?.getDashboardSuccess(response.data.data));
        } catch (error) {
            dispatch(slice?.actions?.hasError(error));
        }
    };
}

export function getTicketTypes(search = null) {
    let searchQuery = '';
    if (search) searchQuery = `&search=${search}`;
    return async () => {
        try {
            const response = await axios.get(`/api/ticketType?${searchQuery}`);
            dispatch(slice.actions.getTicketTypesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addTicketTypes(values) {
    return async () => {
        try {
            await axios.post('/api/ticketType', values);
            const response = await axios.get('/api/ticketType');
            dispatch(slice.actions.addTicketTypesSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Ticket type added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateTicketType(values, ticketTypeId) {
    return async () => {
        try {
            await axios.patch(`/api/ticketType/${ticketTypeId}`, values);
            const response = await axios.get('/api/ticketType');
            dispatch(slice.actions.addTicketTypesSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Ticket type added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function getTicketStatus() {
    return async () => {
        try {
            const response = await axios.get('/api/ticketStatus');
            dispatch(slice.actions.getTicketStatusSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addTicketStatus(values) {
    return async () => {
        try {
            await axios.post('/api/ticketStatus', values);
            const response = await axios.get('/api/ticketStatus');
            dispatch(slice.actions.addTicketStatusSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Ticket status added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}

export function updateTicketStatus(values, ticketStatusId) {
    return async () => {
        try {
            await axios.patch(`/api/ticketStatus/${ticketStatusId}`, values);
            const response = await axios.get('/api/ticketStatus');
            dispatch(slice.actions.addTicketStatusSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Ticket Status added successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again!',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
}
