// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconBox,
    IconTrademark,
    IconWifi,
    IconReportAnalytics,
    IconTicket,
    IconSettings
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconBox,
    IconTrademark,
    IconWifi,
    IconReportAnalytics,
    IconTicket,
    IconSettings
};

// ==============================|| USERS PAGES MENU ITEMS ||============================== //

const user = {
    id: 'user',
    type: 'group',
    children: [
        {
            id: 'userSetting',
            title: <FormattedMessage id="userSetting" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'profile',
                    title: <FormattedMessage id="profile" />,
                    type: 'item',
                    url: '/user-setting/profile'
                }
            ]
        }
    ]
};

export default user;
