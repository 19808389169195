// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';
import { getChillersByCustomer } from './chiller';
import { getAreas } from './masterOption';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    customers: [],
    customerHistory: [],
    searchedCustomers: [],
    stores: [],
    currentCustomer: {},
    currentCustomerSafety: {},
    storeSaftyDetails: {},
    currentCustomerStores: [],
    country: [],
    zone: [],
    totalCutomers: 0,
    totalCustomerHistory: 0,
    perPage: 0,
    pageFrom: 0,
    pageTo: 0,
    lastPage: 0,
    currentPage: 0
};

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CUSTOMERS
        getCustomersSuccess(state, action) {
            state.customers = action.payload.data;
            state.totalCutomers = action.payload.total;
            state.perPage = action.payload.per_page;
            state.pageFrom = action.payload.from;
            state.pageTo = action.payload.to;
            state.lastPage = action.payload.last_page;
            state.currentPage = action.payload.current_page;
        },

        // GET CUSTOMER HISTORY
        getCustomerHistorySuccess(state, action) {
            state.customerHistory = action.payload.data;
            state.totalCustomerHistory = action.payload.total;
            state.perPage = action.payload.per_page;
        },

        // UPDATE CUSTOMERS
        updateCustomersSuccess(state, action) {
            state.currentCustomer = action.payload;
        },

        // GET COUNTRY
        getCountrySuccess(state, action) {
            state.country = action.payload;
        },

        // GET ZONE
        getzoneSuccess(state, action) {
            state.zone = action.payload;
        },

        // ADD CUSTOMERS
        addCustomersSuccess(state, action) {
            state.customers = action.payload;
        },

        // ADD STORES
        getStoreSuccess(state, action) {
            state.stores = action.payload;
        },

        // Set Current Customer
        setCurrentCustomer(state, action) {
            state.currentCustomer = action.payload.data;
            state.currentCustomerSafety = action.payload.safety_level;
        },

        // Set Current Customer Stores
        setCurrentCustomerStores(state, action) {
            state.currentCustomerStores = action.payload.data.data;
            state.storeSaftyDetails = action.payload.safety_level;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers(page, search = null, zone = null, selectedArea = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    let filter = '';

    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    if (zone) filter = `&zone_id=${zone}`;
    if (selectedArea) filter = `&area_id=${selectedArea.map((area) => area.id).join(',')}`;
    console.log(searchQuery, 'sssss');
    return async () => {
        try {
            const response = await axios.get(`/api/customers?page=${page + searchQuery + filter}`);
            dispatch(slice.actions.getCustomersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCustomerHistory(customerID, page, search = null) {
    page = !page ? 1 : page;
    let searchQuery = '';
    if (search) {
        const replacedString = search.replace('+', '%2B');
        searchQuery = `&search=${replacedString.replace(/&/g, '%26')}`;
    }
    return async () => {
        try {
            const response = await axios.get(`/api/customerHistories/${customerID}?page=${page + searchQuery}`);
            dispatch(slice.actions.getCustomerHistorySuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllCustomers() {
    return async () => {
        try {
            const response = await axios.get('/api/customers');
            dispatch(slice.actions.getCustomersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCountry() {
    return async () => {
        try {
            const response = await axios.get('/api/country-list');
            dispatch(slice.actions.getCountrySuccess(response.data.country_list));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getzone(country) {
    return async () => {
        try {
            const response = await axios.get(`/api/featchZoneViaconuntry/${country}`);
            dispatch(slice.actions.getzoneSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addCustomers(customers) {
    customers.safety_level = customers.safetyLevel;
    return async () => {
        try {
            await axios.post('/api/add_customer', customers);
            const response = await axios.get('/api/customers');
            dispatch(slice.actions.getCustomersSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Customer save successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function addStore(stores) {
    return async () => {
        try {
            await axios.post('/api/add_store', stores);
            const response = await axios.get(`/api/get_stores_by_customerid?id=${stores.customer_id}`);
            dispatch(slice.actions.getStoreSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Store save successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function updateStore(stores) {
    return async () => {
        try {
            await axios.post('/api/update_store', stores);
            const response = await axios.get(`/api/get_stores_by_customerid?id=${stores.customer_id}`);
            dispatch(slice.actions.getStoreSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Store update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}

export function getAllTrademark() {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/projects/listing`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function assignChillers(data) {
    return new Promise((resolve, reject) => {
        axios
            .post('/api/assign_chiller', data)
            .then(() => {
                dispatch(getChillersByCustomer(data.customer_id));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Chiller Assign successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );

                resolve();
            })
            .catch((error) => {
                dispatch(slice.actions.hasError(error));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something wents wrong! Please try again',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        actionButton: false
                    })
                );
                reject(error);
            });
    });
}

export function getCustomerById(customerID) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/show_customer?id=${customerID}`)
            .then((response) => {
                dispatch(slice.actions.setCurrentCustomer(response));
                dispatch(getzone(response.data?.data?.country?.id));
                dispatch(getAreas(response.data?.data?.area?.id));
                resolve(response?.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getStoreById(storeId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/show_store?id=${storeId}`)
            .then((response) => {
                dispatch(slice.actions.setCurrentCustomer(response.data));
                resolve(response?.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function getCustomerStore(customerID) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/get_stores_by_customerid?id=${customerID}`)
            .then((response) => {
                dispatch(slice.actions.setCurrentCustomerStores(response.data));
                resolve(response.data);
            })
            .catch((err) => {
                dispatch(slice.actions.hasError(err));
                reject(err);
            });
    });
}

export function updateCustomer(customers, setCustomerDetails) {
    return async () => {
        try {
            const response = await axios.post('/api/update_customer', customers);
            dispatch(slice.actions.updateCustomersSuccess(response.data.data));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Customer update successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );

            const customer = await axios.get(`/api/show_customer?id=${customers.id}`);
            await setCustomerDetails(customer.data.data);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something wents wrong! Please try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    actionButton: false
                })
            );
        }
    };
}
